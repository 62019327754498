
import { CurrentUser, NewUser } from '@/types';
import Vue from '@/vueTyped';
import rules from '@/validation-rules';
import debounce from 'lodash/debounce';
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full.esm';
import PhoneInput from '@/components/PhoneInput.vue';
import ThirdPartyLogInButtons from '@/pages/Account/ThirdPartyLogInButtons.vue';
import TransitionExpand from '@/components/TransitionExpand.vue';

export default Vue.extend({
    components: {
        ValidationProvider,
        ValidationObserver,
        PhoneInput,
        ThirdPartyLogInButtons,
        TransitionExpand,
    },

    props: {
        embedded: Boolean
    },

    data() {
        return {
            form: {
                firstname: '',
                lastname: '',
                email: this.$store.state.account.visitorEmail,
                mobilePhone: '',
                smsPermitted: true,
                password: '',
            },
            rules,
            checkingEmail: false,
            emailInUseError: '',
            registrationInProgress: false,
        };
    },

    computed: {
        currentUser(): CurrentUser | null {
            return this.$store.state.account.currentUser;
        },

        currentUserWasJustCreated(): boolean {
            // Note, not reactive to time;
            // this will only reset when currentUser changes,
            // but that should be enough for this case.
            const veryRecently = new Date();
            veryRecently.setSeconds(veryRecently.getSeconds() - 5);
            return this.currentUser !== null && new Date(this.currentUser.createdAt) >= veryRecently;
        },

        showPhone(): Boolean {
            return true;
        }
    },

    methods: {
        async handleEmailChange() {
            const emailValid = await (this.$refs.emailRef as any).validateSilent();

            if (emailValid.valid) {
                this.validateEmail(this.form.email);
            }
        },

        validateEmail: debounce(async function(this: any, email) {
            this.checkingEmail = true;
            this.emailInUseError = '';
            const result = await this.$store.dispatch('checkEmailValidity', email);
            this.checkingEmail = false;
            if (result.error) {
                this.emailInUseError = this.$t('account.addressInUse');
            }
        }, 500),

        async register() {
            this.registrationInProgress = true;

            const notifyMethod: NewUser['communicationPreferences']['notifyMethod'] = ['email', 'app'];
            if (this.form.smsPermitted) {
                notifyMethod.push('sms');
            }

            const newUser: NewUser = {
                firstName: this.form.firstname,
                lastName: this.form.lastname,
                email: this.form.email,
                mobilePhone: this.form.mobilePhone,
                password: this.form.password,
                addressComponents: {},
                communicationPreferences: { notifyMethod },
                signupSource: this.$store.state.platform,
            };

            const { error } = await this.$store.dispatch('accountRegister', newUser);

            this.registrationInProgress = false;

            if (error) {
                this.$store.dispatch('alertUser', {
                    type: 'error',
                    message: this.$t('account.addressInUse'),
                });
            } else {
                this.$emit('success');
            }
        },

        signOut() {
            this.$store.dispatch('accountLogOut');
        },
    },
});
